<template>
  <div class="login-container">
    <div v-if="pageLoader" class="page-loader">
      <div class="d-flex flex-column" style="margin: 0 auto">
        <img src="../assets/img/rd_prooff_login_logo.svg" alt="Loading..." />
        <div class="loader">
          <div class="progress"></div>
        </div>
      </div>
    </div>
    <!-- <v-container id="login" class="login-background"> -->
    <v-row justify="center" class="">
      <v-col md="5">
        <div class="top">
          <!-- <div class="transparent-card"> -->
          <div class="top-image-container">
            <img
              class="top-image"
              src="../assets/img/rd_prooff_login_logo.svg"
              alt="Logo"
            />
          </div>
          <v-card :loading="loading">
            <v-card-title
              class="mt-1 mb-3 d-flex flex-column align-center title-login"
              >Логин</v-card-title
            >
            <form class="login-form">
              <div class="login">
                <label>Логин</label>
                <br />
                <input
                  v-model="login"
                  type="text"
                  placeholder="Введите ваш логин"
                  class="login_input"
                />
              </div>
              <div class="password">
                <label for="password">Пароль</label>
                <input
                  v-model="password"
                  type="password"
                  placeholder="Введите ваш пароль"
                  class="login_input"
                />
              </div>
            </form>
            <div class="d-flex flex-column align-center">
              <p v-if="sent" class="error-text" style="color: #cf320e">
                Неверный логин или пароль
              </p>
              <a
                v-if="sent"
                class="error-text"
                href=""
                @click.prevent="resetPhoneNumber"
                style="color: #0a7aff"
              >
                Очистить данные
              </a>
            </div>
            <v-col cols="12">
              <div
                class="d-flex flex-column align-center text-center"
                style="margin-bottom: 31px"
              >
                <button type="button" id="sign-in-button" @click="startAuth()">
                  Войти
                </button>
              </div>
            </v-col>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style>
::v-deep .v-sheet.v-card:not(.v-sheet--outlined) {
  /* box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); */
  border-radius: 10px;
  background: var(--06, #fff);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.title-login {
  padding-top: 31px !important;
  color: var(--basic-text-500, #333) !important;
  font-family: Inter !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
.error-text {
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.login-container {
  display: flex;
  align-items: center;
  /* Выравнивание по центру по вертикали */
  justify-content: center;
  /* Выравнивание по центру по горизонтали */
  height: 100vh;
  /* Контейнер занимает 100% высоты окна браузера */
  /* background-image: url("../assets/img/ozo_back.png"); */
  /* Фоновое изображение */
  background: #fafbfd;
  background-size: cover;
  background-position: center;
  /* overflow: hidden;  */
}

.top-image-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 120px;
  height: 100px;
  z-index: 1;
  margin-bottom: 44px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
}

.top-image {
  width: 100%;
  height: 100%;
  /* margin-bottom: 55px; */
}

.login > label {
  font-size: 1.1rem;
  margin-left: 0.6em !important;
}

.password > label {
  font-size: 1.1rem;
  margin-left: 0.6em !important;
}

::v-deep .v-card {
  padding: 24px;
}

.login-form {
  /* padding: 24px; */
  /* margin: 0 40px; */
  /* margin-right: 129px !important;
  margin-left: 129px !important; */
  max-width: 444px;
  margin: auto;
  width: 100%;
}

.login-form :first-child {
  margin: 1px 0 25px 0;
}

.login-form :last-child {
  margin: 1px 0 10px 0;
}

.login_input {
  width: 100%;
  padding: 14px;
  outline: none;
  border-radius: 5px;
  border: 0.5px solid var(--unnamed, #5e5e5e);
  background: #fff;
  box-sizing: border-box;
  border-radius: 5px;
  color: #333333;
  font-size: 16px;
}

.inactive-link {
  color: #e11616 !important;
  /* Примените желаемый стиль для неактивной ссылки */
  cursor: not-allowed;
}

.text-center {
  text-align: center !important;
}

.google-button {
  border-radius: 50px;
  background: var(--background, #f2f2f6);
}

::v-deep .google-button:hover {
  background-color: rgb(52, 75, 88);
}

#sign-in-button {
  border-radius: 8px;
  background: var(--03, #bcb648);
  color: var(--white, #fff);
  padding: 14px 56px;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 235px;
  width: 100%;
}

#sign-in-code {
  padding: 14px 56px;
  border-radius: 8px;
  background: var(--03, #bcb648);
  color: var(--white, #fff);
  text-align: center;
  /* H3 - 16 SemiBold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

::v-deep .v-application a {
  color: #0a7aff;
}
</style>

<script>
import { mapMutations } from "vuex";
export default {
  name: "Login",
  created() {
    if (this.$route.query.reset) {
      // Вызываем метод из стора для сброса состояния пользователя
    }
  },
  methods: {
    ...mapMutations([
      "setToken",
      "setData",
      "setLogged",
      "setRoles",
      "setCurrentRes",
    ]), // Импортируем и используем мутации из стора
    emptyUser() {
      this.setToken(null);
      this.setData({});
      this.setLogged(false);
      this.setRoles([]);
      this.setCurrentRest(null);
    },
    async startAuth() {
      this.loading = true;
      this.pageLoader = true;
      try {
        const response = await this.$http.post("/api/Auth/Login", {
          name: this.login,
          password: this.password,
        });
        const userData = await this.$http.get("/api/Auth/");
        // Успешный ответ от сервера
        await this.$store.dispatch("fetchUser", response.data.user);
        this.loading = false;
        this.handleLoginResponse(userData.data); // Обрабатываем успешный ответ
      } catch (error) {
        // Ошибка от сервера;
        this.sent = true;
        this.loading = false;
        console.error("Ошибка при аутентификации:", error);
        this.error = error; // Отображаем ошибку пользователю
        setTimeout(() => {
          this.pageLoader = false;
        }, 1000); // Отключаем loader в случае ошибки
      }
    },
    handleLoginResponse(data) {
      // Обработка успешного ответа от сервера
      console.log("Успешная аутентификация:", data);
      this.$store.commit("setData", data);
      this.$store.commit("setLogged", true);
      this.$router.push({ path: "/BannerPage" }); // Перенаправьте пользователя на нужную страницу
      setTimeout(() => {
        this.pageLoader = false;
      }, 2000);
    },
    resetPhoneNumber() {
      this.sent = false;
      this.login = null;
      this.password = ""; // Сброс кода, если это также необходимо
      this.recaptchaVerifier = null;
    },
    // async initUser(data) {
    //   try {
    //     await this.$store.dispatch("fetchUser", data.user);
    //     // Выполняем редирект только при успешном завершении fetchUser
    //     // const delayMilliseconds = 1500;
    //     // setTimeout(() => {
    //     //   this.$router.push({ path: "/RestaurantChoose" }); // Перенаправляем после задержки
    //     // }, delayMilliseconds);
    //     setTimeout(() => {
    //       this.pageLoader = false;
    //     },2000)
    //     // this.$router.push({ path: "/admin/manufacturer" },delayMilliseconds);
    //   } catch (error) {
    //     console.error("Ошибка при получении пользователя::", error);
    //     // Обработка ошибки, например, отображение сообщения пользователю
    //   }
    // },
  },
  watch: {
    sent(newSentStatus) {
      if (newSentStatus) {
        this.startResendTimer();
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.resendInterval);
  },

  data: () => ({
    recaptchaInitialized: false,
    sent: false,
    login: null,
    password: "",
    loading: false,
    error: null,
    resendDisabled: false,
    resendTimer: 0,
    resendInterval: null,
    pageLoader: false,
    user: {},
  }),
};
</script>
